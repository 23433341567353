import React from "react";
import { Layout } from "antd";
import { Link } from "gatsby";
import TopNav from "./TopNav";

export default function TopBar() {
  return (
    <Layout.Header className="top-bar">
      <Link className="logo" to="/" title="דף הבית" />
      <TopNav />
    </Layout.Header>
  );
}
