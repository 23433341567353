import styled from "@emotion/styled";
import React from "react";

const CustomHeader = styled.h1`
  background: #efefef;
  padding: 0.5rem 1rem;
  color: #444;
  border-right: 4px solid #1890ff;
`;

export default function ContentHeader({ children }) {
  return <CustomHeader>{children}</CustomHeader>;
}
