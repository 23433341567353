import React from "react";
import styled from "@emotion/styled";
import { Layout } from "antd";
import { FacebookOutlined, WhatsAppOutlined } from "@ant-design/icons";

import Sponsors from "./Sponsors";
import Copyright from "./Copyright";

const Contact = styled.div`
  background: aliceblue;
  margin: 1rem 0;
  padding: 2rem;
  line-height: 1.6rem;

  ul {
    display: flex;
    list-style: none;
    justify-content: space-between;
    margin: 0;
    flex-wrap: wrap;

    li {
      margin: 0;
    }
  }

  a {
    color: green;
  }
`;

const FacebookLink = styled.div`
  background-color: #1890ff;
  display: inline-block;
  background-color: #1890ff;
  display: inline-block;
  margin-top: 1rem;
  padding: 1rem;

  a {
    color: white;
    padding: 1rem;
    border-radius: 4px;
  }
`;

export default function Footer() {
  return (
    <Layout.Footer style={{ textAlign: "center" }}>
      <Sponsors />
      {/* <Button  style={{ marginTop: "1rem" }} type="primary" shape="round" size="large">
        <a
          style={{ color: "white" }}
          title="הרשמה אולטרה עוטף עזה 2022"
          href="https://eventbuzz.co.il/lp/event/trmkb"
        >
          להרשמה למירוץ לחצו כאן
        </a>
      </Button> */}

      <br />

      <FacebookLink>
        <a href="https://www.facebook.com/ultraotefaza">
          <FacebookOutlined /> דף הפייסבוק של המירוץ
        </a>
      </FacebookLink>

      <Contact>
        <h4 id="contact-us">יצירת קשר</h4>
        <div>הקבוצה האתגרית עוטף עזה</div>
        <div>יוסף למדן 054-5684435</div>
        <div>lamdanyosef@gmail.com</div>
        <div>
          <a href="https://wa.me/972545684435" target="_blank" rel="noreferrer">
            <WhatsAppOutlined /> יצירת קשר דרך וואטסאפ
          </a>
        </div>
      </Contact>
      <Copyright />
    </Layout.Footer>
  );
}
