import React from "react";

import { Menu } from "antd";
import { Link } from "gatsby";
import { FacebookOutlined, MenuOutlined } from "@ant-design/icons";
import SubMenu from "antd/lib/menu/SubMenu";
import styled from "@emotion/styled";

const raceSections = [
  { path: "/section1", label: "קטע מספר 1 – אנדרטת הפלדה – תלמי יוסף" },
  { path: "/section2", label: "קטע מספר 2 – תלמי יוסף – גבולות ( דרך השדות )" },
  { path: "/section3", label: "קטע מספר 3 גבולות – צאלים" },
  { path: "/section4", label: "קטע מספר: 4 מצאלים לבאר רבבה" },
  {
    path: "/section5",
    label: "קטע מספר 5: מבאר רבבה לפארק אשכול (גן לאומי הבשור)",
  },
  {
    path: "/section6",
    label: "קטע מספר 6: מפארק אשכול (גן לאומי הבשור) לסכר רעים",
  },
  { path: "/section7", label: "קטע מספר 7: מסכר רעים לגשר רעים" },
  { path: "/section8", label: "קטע מספר 8: מגשר רעים לבארי (חנות לה-מדווש)" },
  { path: "/section9", label: "קטע מספר 9: מבארי לנחל עוז" },
  { path: "/section10", label: "קטע מספר 10: מנחל עוז לאנדרטת 'החץ השחור'" },
  {
    path: "/section11",
    label: "קטע מספר 11: מאנדרטת 'חץ שחור' לקיבוץ ארז מפגש נחל שקמה",
  },
  {
    path: "/section12",
    label: "קטע מספר 12: מקיבוץ ארז מפגש נחל שקמה לחוף זיקים",
  },
  { path: "/section10km", label: `מקצה 10 ק"מ` },
  { path: "/section5km", label: `מקצה 5 ק"מ` },
];

const galleries = [{ path: "/gallery-2017", label: `מירוץ 2017` }];

const pages = [
  { path: "/", label: "ראשי" },
  { path: "/register", label: "רישום" },
  { path: "/schedule", label: 'לו"ז מרוץ' },
  { path: "/sections", label: "מקטעים", subPages: raceSections },
  { path: "/stations", label: "תחנות החלפה" },
  { path: "/rules", label: "תקנון" },
  { path: "/events", label: "אירועי דגל" },
  { path: "/info-for-runners", label: "דגשים לרצים" },
  { path: "/cut-off", label: "Cut-off" },
  { path: "/gallery", label: "תמונות", subPages: galleries },
  {
    path: "https://www.facebook.com/ultraotefaza",
    label: "פייסבוק",
    title: "דף הפייסבוק של אולטרה עוטף עזה",
  },
];

const FacebookMenuItem = styled.span`
  color: #1890ff;
  font-weight: bold;
`;

export default function TopNav() {
  return (
    <Menu
      overflowedIndicator={<MenuOutlined />}
      theme="dark"
      mode="horizontal"
      triggerSubMenuAction="click"
    >
      {pages.map((page) =>
        page.subPages ? (
          SectionSubMenu(page)
        ) : (
          <Menu.Item key={page.path}>
            <Link
              to={page.path}
              className={page.className || ""}
              title={page.title}
            >
              {page.path.includes("facebook") ? (
                <FacebookMenuItem>
                  <FacebookOutlined /> {page.label}
                </FacebookMenuItem>
              ) : (
                <>{page.label} </>
              )}
            </Link>
          </Menu.Item>
        )
      )}
    </Menu>
  );
}

function SectionSubMenu(page) {
  return (
    <SubMenu key={page.path} title={page.label}>
      {page.subPages.map((sp) => (
        <Menu.Item key={sp.path}>
          <Link to={sp.path}>{sp.label}</Link>
        </Menu.Item>
      ))}
    </SubMenu>
  );
}
