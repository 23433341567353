import React from "react";
import styled from "@emotion/styled";

import ContentHeader from "./ContentHeader";

const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 50em;
  padding-top: 1rem;
`;
const Content = styled.div`
  min-height: 90vh;
  line-height: 1.5;
  padding: 0 1em 4em 1em;

  p {
    font-size: 1.2em;
  }

  h2 {
    margin-top: 1em;
    padding-top: 1em;
  }
`;

export default function SectionContent({ title, children }) {


  return (
    <Wrapper>
      <ContentHeader>{title}</ContentHeader>
      <Content>{children}</Content>
    </Wrapper>
  );
}
