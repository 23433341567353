import React from "react";
import { Layout, ConfigProvider } from "antd";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

import "antd/dist/antd.css";
import "../styles/index.css";

import TopBar from "./TopBar";
import SiteContent from "./SiteContent";
import Footer from "./Footer";

export default function LayoutComponent({ children, title, path }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
          }
        }
      }
    `
  );

  const defaultTitle = site.siteMetadata?.title;
  const description = `אולטרה עוטף עזה 2022 - הרשמה נפתחה`;
  const imagePath = `${site.siteMetadata?.siteUrl}/ultra-otef.jpg`;
  const url = `${site.siteMetadata?.siteUrl}${path}`;

  return (
    <>
      <Helmet
        title={title}
        titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
        meta={[
          {
            name: "description",
            content: description,
          },
          {
            name: "image",
            content: imagePath,
          },
          {
            property: "og:site_name",
            content: defaultTitle,
          },
          {
            property: `og:title`,
            content: `${title} | ${defaultTitle}`,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            property: `og:image:type`,
            content: `image/jpeg`,
          },
          {
            property: `og:image:width`,
            content: `256`,
          },
          {
            property: `og:image:height`,
            content: `256`,
          },
          {
            property: `og:description`,
            content: description,
          },
          {
            property: `og:image`,
            // You should ideally replace the hardcoded URL below with a value you set
            // in your gatsby-config.js file.  And import all shared site metadata into
            // this component with the useStaticQuery hook.
            content: imagePath,
            // itemProp: 'image'
          },
          {
            property: `og:url`,
            content: url,
          },
          {
            property: `og:locale`,
            content: "he_IL",
          },
        ]}
      >
        <html lang="he-IL" prefix="og: https://ogp.me/ns#" />
        <title>{title}</title>
      </Helmet>
      <ConfigProvider direction="rtl">
        <Layout className="layout">
          <TopBar />
          <SiteContent>{children}</SiteContent>
          <Footer />
        </Layout>
      </ConfigProvider>
    </>
  );
}
