import React from "react";
import styled from "@emotion/styled";

import eshkolImg from "../images/sponsors/eshkol.jpg";
import hofAshkelonImg from "../images/sponsors/hof-ashkelon.jpg";
import shaarHanegevImg from "../images/sponsors/shaar-hanegev.jpeg";
import etgaritImg from "../images/sponsors/etgarit.jpg";
import shderotImg from "../images/sponsors/shderot.jpg";
import ugdatAzaImg from "../images/sponsors/ugdat-aza.jpeg";
import sdotNegevImg from "../images/sponsors/sdot-negev.jpg";
import hasborImg from "../images/sponsors/habsor.jpg";
import netivotImg from "../images/sponsors/netivot-logo.png";


const sponsors = [
  {
    img: etgaritImg,
    link: "https://lamdanteam.wixsite.com/lamdanteamtriathlon",
    title: "",
  },
  {
    img: ugdatAzaImg,
    link: "https://he.wikipedia.org/wiki/%D7%90%D7%95%D7%92%D7%93%D7%AA_%D7%A2%D7%96%D7%94",
    title: "",
  },
  {
    img: shaarHanegevImg,
    link: "https://www.sng.org.il/",
    title: "",
  },
  {
    img: shderotImg,
    link: "https://www.e-sderot.org.il/",
    title: "",
  },
  {
    img: sdotNegevImg,
    link: "https://www.sdotnegev.org.il",
    title: "",
  },
  {
    img: hofAshkelonImg,
    link: "https://www.hof-ashkelon.org.il/",
    title: "",
  },
  {
    img: eshkolImg,
    link: "http://eshkol.info/",
    title: "",
  },
  {
    img: hasborImg,
    link: "https://www.habsor.co.il/",
    title: "",
  },
  {
    img: netivotImg,
    link: "https://www.netivot.muni.il/",
    title: "",
  },
];

const SponsorsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  background: white;
  padding: 1rem;

  a {
    margin: 1rem;
  }

  img {
    height: auto;
    width: 100%;
    border: 2px solid white;
    &:hover {
      border: 2px solid aliceblue;
    }
  }
`;

export default function Sponsors() {
  return (
    <SponsorsContainer>
      {sponsors.map((s) => (
        <a href={s.link} key={s.link}>
          <img src={s.img} alt={s.link} width="350" height="173" />
        </a>
      ))}
    </SponsorsContainer>
  );
}
