import React from "react";
import styled from "@emotion/styled";

const Container = styled.div`
  font-size: 0.85em;
  text-shadow: 1px 1px 1px white;
  color: #333;
  background: #efefef;
  margin-top: 1rem;
  opacity: 0.8;
  &:hover {
    opacity: 1;
  }

  a {
    color: #333;
    border-bottom: 1px solid silver;
  }
`;

export default function Copyright() {
  return (
    <Container>
      Yosef Lamdan @ {new Date().getFullYear()} | Site logo by{" "}
      <a href="https://www.3plus.co.il/">Nir Cohen</a>
    </Container>
  );
}
